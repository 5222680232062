import React from 'react';
import {
  CodeSnippet,
  Link,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const contrastSnippet = require('raw-loader!../../../../data/snippets/accessibility-color-contrast.example');
const typeSnippet = require('raw-loader!../../../../data/snippets/accessibility-color-type.example');

const IndexPage = ({}) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Color"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Color"
      />

      <Section title="Accessible Color Rules">
        <Paragraph>
          Adhere to the following criteria around using color in interfaces,
          provided by the Web Content Accessibility Guidelines (WCAG):
        </Paragraph>
        <SectionSubhead>Sensory Characteristics</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/sensory-characteristics.html">
            According to WCAG 1.3.3 (Sensory Characteristics)
          </Link>
          , instructions for using or understanding content on the page cannot
          rely on color, size, visual orientation, shape, or sound. For example,
          instead of telling a user to "press the blue button on the right to
          continue," tell them to "press the 'Submit' button to continue."
        </Paragraph>

        <SectionSubhead>Use of Color</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html">
            WCAG 1.4.1 (Use of Color)
          </Link>
          requires that color cannot be the only visual means of conveying
          information, indicating an action, prompting a response or
          distinguishing a visual element.
        </Paragraph>

        <SectionSubhead>Text Contrast</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
            WCAG 1.4.3 (Contrast)
          </Link>{' '}
          sets a minimum color contrast ratio of <strong>3:1</strong> for bold
          text at least 18.5px or non-bold text 24px and above. For all other
          cases, minimum color contrast ratio should be <strong>4.5:1</strong>.
          If your project is setup with Storybook, use their{' '}
          <Link href="https://storybook.js.org/addons/@storybook/addon-a11y">
            Accessibility add-on panel
          </Link>{' '}
          to find any violations. Use Uniform color variables whenever possible
          to ensure compliance.
        </Paragraph>
        <CodeSnippet disableCodeEditing code={contrastSnippet} />
        <Paragraph>
          To make things even easier, just use one of our{' '}
          <Link href="/components/type/code">Type</Link> components.
        </Paragraph>
        <CodeSnippet disableCodeEditing code={typeSnippet} />

        <SectionSubhead>Non-text Contrast</SectionSubhead>
        <Paragraph>
          The other color contrast rule,{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html">
            WCAG 1.4.11 (Contrast)
          </Link>
          , requires that non-text colors that appear adjacent to each other in
          interactive elements and meaningful graphics have a{' '}
          <strong>3:1</strong> color contrast ratio. This rule applies primarily
          to fills, borders and outlines on interactive elements like buttons,
          or larger graphics that use color to show different fields or values.
          Designs with custom graphics or data visualizations should be tested
          for compliance before implementing.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
